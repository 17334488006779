import React, { useState } from 'react';
import SpotifyPlayer, { spotifyApi } from 'react-spotify-web-playback';

const SongGuess = ({ token, selectedPlaylistId, handleBackButtonClick }) => {

    const [revealed, setRevealed] = useState(false);
    const [currentSong, setCurrentSong] = useState(null);

    const revealSong = () => {
        console.log(currentSong);
        setRevealed(true);
    };

    const handleStatusUpdate = (status) => {
        if (status.isPlaying) {
            spotifyApi.getTrack(token, status.track.id).then((response) => {
                setCurrentSong(response);
            });
            setRevealed(false);
        }

        if (!status.shuffle) {
            console.log('Shuffle is disabled. Enabling shuffle');
            spotifyApi.shuffle(token, true, status.deviceId);
        }

    };

    return (
        <div className="game">
            <div className='game-buttons'>
                <button className="back-btn" onClick={handleBackButtonClick}>Back to Playlist Selection</button>
                <button className='reveal-btn' onClick={() => revealSong()}>Reveal Song Details</button>
            </div>

            {currentSong && revealed && (
                <div className="song-info">
                    <img src={currentSong.album.images[0].url} alt={currentSong.name} />
                    <div>
                        <div className='song-title'>{currentSong.name}</div>
                        <div className='song-artist'>by {currentSong.artists.map((artist) => artist.name).join(', ')}</div>
                        <div className='song-release-date'>Released: {currentSong.album.release_date}</div>
                    </div>
                </div>
            )}
            <div className="player">
                <SpotifyPlayer
                    name="Guess the Song Web Player"
                    token={token}
                    uris={['spotify:playlist:' + selectedPlaylistId]}
                    play={true}
                    hideCoverArt={true}
                    callback={handleStatusUpdate}
                />
            </div>
        </div>
    );
}

export default SongGuess;