import React, { useState, useEffect } from 'react';
import { loginUrl, getPlaylists } from './services/spotify';
import Playlist from './components/Playlist';
import './App.css';
import Header from './components/Header';
import SongGuess from './components/SongGuess';

function App() {
  const [token, setToken] = useState('');
  const [playlists, setPlaylists] = useState([]);
  const [selectedPlaylistId, setSelectedPlaylistId] = useState(null);
  const [headerText, setHeaderText] = useState("");
  const [loading, setLoading] = useState(false); // Track loading state

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const token = hash.split('&')[0].split('=')[1];
      setToken(token);
      localStorage.setItem("spotifyToken", token);
      window.location.hash = "";
    } else {
      const savedToken = localStorage.getItem("spotifyToken");
      if (savedToken) {
        setToken(savedToken);
      }
    }
  }, []);

  useEffect(() => {
    if (token) {
      setLoading(true); // Start loading
      getPlaylists(token)
        .then((data) => {
          setPlaylists(data);
          setHeaderText("Select a Playlist");
        })
        .catch((error) => {
          console.error("Error fetching playlists:", error);
        })
        .finally(() => {
          setLoading(false); // End loading
        });
    }
  }, [token]);

  const handleBackButtonClick = () => {
    setSelectedPlaylistId(null);
    setHeaderText("Select a Playlist");
  };

  return (
    <div className="App">
      {!token ? (
        <a href={loginUrl} className="login-btn">
          Start Game
        </a>
      ) : (
        <div>
          <Header setToken={setToken} headerText={headerText} />
          <div className="main-content">
            {!selectedPlaylistId ? (
              loading ? (
                <div className="loading-spinner"> {/* Loading spinner container */}
                  <div className="spinner"></div>
                </div>
              ) : (
                <Playlist
                  playlists={playlists}
                  setSelectedPlaylistId={setSelectedPlaylistId}
                  setHeaderText={setHeaderText}
                />
              )
            ) : (
              <SongGuess
                token={token}
                selectedPlaylistId={selectedPlaylistId}
                handleBackButtonClick={handleBackButtonClick}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
