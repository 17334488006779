import React from "react";

const Header = ({ setToken, headerText }) => {

    const handleLogout = () => {
        setToken('');
        localStorage.removeItem("spotifyToken");
    };

    return <header className="header">
        <div className="header-text">{headerText}</div>
        <button className="logout-btn" onClick={handleLogout}>Logout</button>
    </header>
}

export default Header;