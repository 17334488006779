import axios from 'axios';

const CLIENT_ID = '9168a1da19684419bd1cc80684683c4d';
const REDIRECT_URI = 'https://songs.bluwhale.de';
const AUTH_ENDPOINT = 'https://accounts.spotify.com/authorize';
const SCOPES = ['playlist-read-private', 'playlist-read-collaborative', 'streaming', 'user-read-playback-state', 'user-modify-playback-state', 'user-read-email', 'user-read-private'];

export const loginUrl = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPES.join(
    '%20'
)}&response_type=token&show_dialog=true`;


export const getUserId = async (accessToken) => {
    try {
        const response = await axios.get('https://api.spotify.com/v1/me', {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data.id;
    } catch (error) {
        console.error('Error fetching user ID:', error.message);
        throw error;
    }
};

export const getPlaylists = async (accessToken) => {
    try {
        const userId = await getUserId(accessToken) || "me";
        const limit = 50;
        let fetchedPlaylists = [];
        let totalNumberOfPlaylists = Infinity;

        for (let offset = 0; offset < totalNumberOfPlaylists; offset += limit) {
            const response = await axios.get(`https://api.spotify.com/v1/users/${userId}/playlists`, {
                headers: { Authorization: `Bearer ${accessToken}` },
                params: { limit, offset },
            });

            const { items, total } = response.data;

            if (!items || items.length === 0) break;

            totalNumberOfPlaylists = total;
            fetchedPlaylists = [...fetchedPlaylists, ...items];

            console.log(`Fetched ${fetchedPlaylists.length} of ${total} playlists`);
        }

        return fetchedPlaylists;
    } catch (error) {
        console.error('Error fetching playlists:', error.message);
        throw error;
    }
};

