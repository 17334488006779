import React from 'react';

function Playlist({ playlists, setSelectedPlaylistId, setHeaderText }) {

    const handlePlaylistClick = (playlistId) => {
        setSelectedPlaylistId(playlistId);
        setHeaderText("Guess the song!");
    };

    return (
        <div className="playlist-grid">
            {playlists
                .filter((playlist) => playlist != null)
                .map((playlist) => (
                    <div
                        className="playlist-item"
                        key={playlist.id}
                        onClick={() => handlePlaylistClick(playlist.id)}
                    >
                        <img
                            className="playlist-image"
                            src={playlist.images[0]?.url}
                            alt={playlist.name}
                        />
                        <div className="playlist-overlay">
                            <div className="playlist-name">{playlist.name}</div>
                            <div className="playlist-tracks">{playlist.tracks.total} tracks</div>
                        </div>
                    </div>
                ))}
        </div>
    );
}

export default Playlist;
